

































































































































































































































































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import ImageInput from "@/components/ImageInput.vue";
import moment from "moment";

export default Vue.extend({
  components: { ImageInput },
  data() {
    return {
      userManager,
      dialog: false,
      form: false,
      dialogDelete: false,
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      headers: [
        { text: "Mã đơn hàng", value: "virtual_package_id" },
        { text: "Chuyển đơn", value: "package_id" },
        { text: "Tên kinh doanh", value: "sale_name" },
        { text: "Khách hàng", value: "customer_name" },
        { text: "NV Hỗ trợ", value: "support_user_name" },
        { text: "NV Giao dịch", value: "transaction_user_id" },
        { text: "KT Xác nhận", value: "status" },
        { text: "Nợ tiền hàng", value: "debt" },
        { text: "Phí dịch vụ", value: "fee_service" },
        { text: "NDT", value: "total_price_ndt" },
        { text: "Tiền hàng", value: "total_price_vnd" },
        //{text: "Giá khối", value: "fee_per_volume"},
        //{text: "Giá cân", value: "fee_per_weight"},
        //{text: "Ủy thác", value: "fee_trust"},
        { text: "Phí khác", value: "fee_other" },
        { text: "Lợi nhuân thanh toán", value: "profit" },
        { text: "Ghi chú", value: "money_note" },
        { text: "Ngày tạo", value: "created_at" },
        { text: this.$t("IMAGE"), value: "images" },
        { text: this.$t("ACTIONS"), value: "actions", sortable: false },
      ],
      options: {},
      loading: false,
      data: [],
      dataCount: 0,
      allTotalPriceVnd: 0,
      search: "",
      status: "",
      currentDate: "",
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      transactionUsers: [],
      selectedItems: [],
      listStatus: [
        {
          value: "Pending",
          text: "Chờ xác nhận",
        },
        {
          value: "Approved",
          text: "Xác nhận",
        },
        {
          value: "DeleteTem",
          text: "Tạm xóa",
        },
      ],
      urlFunc(item) {
        return Vue.filter("virtualPackageImageUrl")(item);
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Tạo đơn thanh toán" : this.$t("EDIT");
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    currentDate() {
      this.initialize();
    },
  },
  methods: {
    async initialize() {
      this.loading = true;
      let status = "";
      let operator = "equal_to";
      if (this.status === "Approved") {
        status = "Approved";
      } else if (this.status === "Pending") {
        status = "Pending";
      } else if (this.status === "DeleteTem") {
        status = "";
        operator = "greater_than";
      }

      const filters = [
        {
          key: "virtual_package_id",
          operator: "match",
          value: this.search,
        },
        {
          key: "status",
          operator: "match",
          value: status,
        },
        {
          key: "deleted_time",
          operator: operator,
          value: 0,
        },
      ];

      if (this.currentDate) {
        const m = moment(this.currentDate);
        filters.push({
          key: "created_at",
          operator: ">=",
          value: m.startOf("month").format("Y-MM-DD"),
        });
        filters.push({
          key: "created_at",
          operator: "<=",
          value: m.endOf("month").format("Y-MM-DD"),
        });
      }

      const { items, count, allTotalPriceVnd } =
        await apiClient.virtualPackageGetAll({
          options: this.options,
          filters,
        });
      this.data = items;
      this.dataCount = count;
      this.allTotalPriceVnd = allTotalPriceVnd;
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.closeDelete();
      await apiClient.virtualPackageDelete(this.editedItem);
      await this.initialize();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      const item = { ...this.editedItem };
      let result;
      if (this.editedIndex > -1) {
        result = await apiClient.virtualPackageUpdate(item);
      } else {
        result = await apiClient.virtualPackageCreate(item);
      }
      if (result) {
        this.close();
        await this.initialize();
      }
    },
    async restoreItem(item) {
      await apiClient.virtualPackageRestore(item);
      await this.initialize();
    },
    async approveItem(item) {
      await apiClient.virtualPackageUpdate({
        id: item.id,
        status: "Approved",
      });
      await this.initialize();
    },
    async cancelApproveItem(item) {
      await apiClient.virtualPackageUpdate({
        id: item.id,
        status: "Pending",
      });
      await this.initialize();
    },
    exportItems(selectedOnly) {
      if (selectedOnly) {
        location.href = apiClient.virtualPackageExport(
          null,
          this.selectedItems.map((item) => item.id)
        );
      } else {
        location.href = apiClient.virtualPackageExport();
      }
    },
  },
  async created() {
    if (!userManager.checkRole("t")) {
      const index = this.headers.findIndex((item) => item.value === "profit");
      if (index !== -1) {
        this.headers.splice(index, 1);
      }
    }

    const transactionUsers = await apiClient.userGetAll({
      filters: [
        {
          key: "roles",
          operator: "equal_to",
          value: "gd",
        },
      ],
    });
    for (const user of transactionUsers.items) {
      this.transactionUsers.push({
        text: user.name,
        value: user.id,
      });
    }
  },
});
